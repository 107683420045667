import { Icons } from '@/components/ui/Icons'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import type { GetStaticProps, GetStaticPropsContext } from 'next'
import { DefaultLocale, NewOrder, NewOrderBundle } from '@/enums/general'
import { useTranslation } from 'next-i18next'
import Button from '@/components/ui/Button'
import { getCookie } from '@/utils/cookies'
import { ChatIdCookie } from '@/enums/cookies'
import { ulid } from 'ulid'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useSetUser } from '@/hooks/utils/useSetUser'
import MetaData from '@/components/lib/MetaData'
import { useLocalStorage } from 'usehooks-ts'
import { User } from '@/core/services/types/getAuthMe/responseTypes'
import { storageKeys } from '@/enums/localStorage'
import { useSetTestVariant } from '@/hooks/utils/useSetTestVariant'

export default function Index() {
  const { t } = useTranslation('common')
  const [loading, setLoading] = useState<boolean>(false)
  const router = useRouter()
  const [_1, setStoredUser] = useLocalStorage<Omit<User, 'paymentContracts'> | null>(storageKeys.user, null)
  const [_2, setClickedOrderBundle] = useLocalStorage<NewOrderBundle | null>(storageKeys.orderBundle, null)
  const [_3, setClickedOrder] = useLocalStorage<NewOrder | null>(storageKeys.order, null)

  useSetUser()
  useSetTestVariant()

  useEffect(() => {
    const hasExistingChat = getCookie(ChatIdCookie)

    if (!hasExistingChat) {
      setStoredUser(null)
      setClickedOrderBundle(null)
      setClickedOrder(null)
    }
  }, [setStoredUser, setClickedOrderBundle, setClickedOrder])

  const onClick = async () => {
    setLoading(true)

    const chatId = getCookie(ChatIdCookie) || ulid()

    await router.push(`/chat/${chatId}`)
  }

  return (
    <>
      <MetaData />
      <div className="body-small text-neutral-800 bg-chat h-full w-full flex justify-center items-center">
        <div className="flex flex-col justify-center items-center">
          <Icons.logo className="h-20 w-20 inline-block" />
          <p className="font-bold mt-4">{t('GetStarted.WelcomeTitle')}</p>
          <p className="mb-4">{t('GetStarted.WelcomeSubTitle')}</p>
          <Button loading={loading} onClick={onClick} icon={<Icons.chevronRight className="h-3 w-3 ml-2 fill-neutral-00" />}>
            {t('GetStarted.WelcomeButtonCTA')}
          </Button>
          <p className="pt-14 px-4 text-center">
            <span>{t('Chat.General.AlreadyAssisted')}</span>
            <br />
            <a href={`${process.env.NEXT_PUBLIC_MAIN_WEBSITE_BASEURL}/account`} className="text-sm text-gray-700 underline">
              {t('Chat.General.GoToAccount')}
            </a>
          </p>
        </div>
      </div>
    </>
  )
}

export const getStaticProps: GetStaticProps = async ({ locale }: GetStaticPropsContext) => {
  return {
    props: {
      ...(await serverSideTranslations(locale || DefaultLocale, ['common']))
    }
  }
}
