import { useEffect } from 'react'
import { useSearchParams } from 'next/navigation'
import { removeCookie, setCookie } from '@/utils/cookies'
import { OTATestVariant } from '@/enums/cookies'

export const useSetTestVariant = () => {
  const searchParams = useSearchParams()
  const isTestVariant = searchParams.has('test')
  const isNormalVariant = searchParams.has('regular')

  useEffect(() => {
    if (isTestVariant) {
      setCookie(OTATestVariant, 'true')
    } else if (isNormalVariant) {
      removeCookie(OTATestVariant)
    }
  }, [isTestVariant, isNormalVariant])
}
